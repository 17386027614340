<template>
    <div>
        <div class="row">
            <div class="col-4">
                <div class="card">
                    <div class="card-header tx-white">
                        Scan Enquiries (v2)
                    </div>
                    <div class="card-body">

                        <div class="form-group row">
                            <label class="col-md-3 col-form-label">Reference <span class="text-danger">*</span></label>
                            <div class="col-md-9">
                                <input v-on:keypress.enter="addCurrent" id="referenceInput" type="text" name="name" v-model="current" class="form-control form-control-dark" :disabled="!editable">
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3 col-form-label">Credit <span class="text-danger">*</span></label>
                            <div class="col-md-9">
                                <input type="number" min="0.00" step="0.01" name="credit" v-model="credit" class="form-control form-control-dark" :disabled="!editable">
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3 col-form-label">Cheque Number <span class="text-danger">*</span></label>
                            <div class="col-md-9">
                                <input type="text" name="cheque_number" v-model="cheque_number" class="form-control form-control-dark" :disabled="!editable">
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3 col-form-label">Posted <span class="text-danger">*</span></label>
                            <div class="col-md-9">
                                <input type="date" name="date" :value="posted" class="form-control form-control-dark disabled" disabled>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3 col-form-label">Signed By <span class="text-danger">*</span></label>
                            <div class="col-md-9">
                                <input type="text" name="name" :value="$store.state.auth.user.email" class="form-control form-control-dark disabled" disabled>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3 col-form-label">Reference <span class="text-danger">*</span></label>
                            <div class="col-md-9">
                                <input type="text" name="batchref" :value="batchref" class="form-control form-control-dark disabled" disabled>
                            </div>
                        </div>

                        <h1 class="tx-center tx-success">{{references.length}} Enquiries = £{{value}}</h1>
                        <h2 v-if="duplicates > 0" class="tx-center tx-danger">{{duplicates}} Duplicates</h2>

                        <button v-if="editable && !(isPending && !$can('batch-post'))" :disabled="posting" class="btn btn-primary pd-x-30 pd-y-15 tx-uppercase tx-bold tx-spacing-6 tx-12 wd-100p mt-2" @click="postBatch">Post Batch</button>
                        <button v-else class="btn btn-primary-outline pd-x-30 pd-y-15 tx-uppercase tx-bold tx-spacing-6 tx-12 wd-100p mt-2" @click="newBatch">New Batch</button>
                        
                        <button v-if="editable && isPending && $can('batch-post')" :disabled="generating" class="btn btn-primary pd-x-30 pd-y-15 tx-uppercase tx-bold tx-spacing-6 tx-12 wd-100p mt-2" @click="generateBatch">Generate Batch Enquiry</button>
                    </div>
                </div>

                <div class="card mt-4">
                    <div class="card-header tx-white">
                        Pending Batches
                    </div>
                    <div class="card-body">

                        <table width="100%" class="table table-striped table-hover tx-white">
                            <thead class="thead-colored thead-primary">
                            <tr>
                                <th>Created At</th>
                                <th># of Claims</th>
                                <th>Estimated Weight</th>
                                <th>Status</th>
                            </tr>
                            </thead>
                            <tbody>
                              <tr v-for="batch in pendingBatches" :key="'batch-' + batch.id" @click="selectPendingBatch(batch)" style="cursor: pointer;">
                                <td>{{batch.created_at}}</td>
                                <td>{{batch.total_enquiries}}</td>
                                <td>{{batch.total_weight}}g</td>
                                <td>
                                    <span v-if="batch.status === 'Pending'" class="badge badge-warning">Pending</span>
                                    <span v-if="batch.status === 'Full'" class="badge badge-success">Full</span>
                                </td>
                              </tr>
                            </tbody>
                        </table>

                    </div>
                </div>

                <div class="card mt-4">
                    <div class="card-header tx-white">
                        Previous Batches
                    </div>
                    <div class="card-body">

                         <div class="form-group row">
                            <label class="col-md-3 col-form-label">Reference</label>
                            <div class="col-md-9">
                                <input type="text" name="name" v-model="filters.reference" class="form-control form-control-dark">
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3 col-form-label">Cheque Number</label>
                            <div class="col-md-9">
                                <input type="text" name="name" v-model="filters.cheque_number" class="form-control form-control-dark">
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3 col-form-label">Date Posted</label>
                            <div class="col-md-9">
                                <input type="date" name="date" :value="filters.date" class="form-control form-control-dark disabled">
                            </div>
                        </div>

                        <button class="btn btn-primary mg-b-20 pd-x-30 pd-y-15 tx-uppercase tx-bold tx-spacing-6 tx-12 wd-100p mt-2" @click="loadPreviousBatches(1)">Search</button>

                        <pagination :data="pagination" @search="loadPreviousBatches"></pagination>

                        <table width="100%" class="table table-striped table-hover tx-white">
                            <thead class="thead-colored thead-primary">
                            <tr>
                                <th>Date</th>
                                <th>Reference</th>
                                <th>Signed By</th>
                            </tr>
                            </thead>
                            <tbody>
                              <tr v-for="batch in previous" :key="'batch-' + batch.id" @click="selectBatch(batch)" style="cursor: pointer;">
                                <td>{{batch.created_at}}</td>
                                <td>{{batch.reference}}</td>
                                <td>{{batch.email.split('@')[0]}}</td>
                              </tr>
                            </tbody>
                        </table>

                        <pagination :data="pagination" @search="loadPreviousBatches"></pagination>
                    </div>
                </div>
            </div>
            <div class="col-8">
              <div v-if="rejected.length > 0" class="card mg-b-20">
                    <div class="card-header tx-white">
                        Rejected Enquiries
                    </div>
                    <div class="card-body">
                        <table width="100%" class="table table-striped table-hover tx-white">
                            <thead class="thead-colored thead-primary">
                                <tr>
                                    <th>Reference</th>
                                    <th>Message</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="r in rejected">
                                    <td>{{r.reference}}</td>
                                    <td>{{r.message}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header tx-white">
                        Enquiries
                    </div>
                    <div class="card-body">



                        <table v-if="!isPending" width="100%" class="table table-striped table-hover tx-white">
                            <thead class="thead-colored thead-primary">
                                <tr>
                                    <th>Reference</th>
                                    <th>Site</th>
                                    <th>Type</th>
                                    <th>Value</th>
                                    <th v-if="editable"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="r in references" :class="{'duplicates': r.duplicates}" style="cursor: pointer">
                                    <td>{{r.reference}}</td>
                                    <td>{{r.site}}</td>
                                    <td>{{r.type}}</td>
                                    <td>£{{r.value}}</td>
                                    <td v-if="editable"><button @click="remove(r)" class="btn btn-sm btn-danger"><i class="fas fa-trash"/></button></td>
                                </tr>
                                <tr v-if="references.length < 1">
                                    <td colspan="5" class="text-center">No Enquiries</td>
                                </tr>
                            </tbody>
                        </table>

                        <table v-else width="100%" class="table table-striped table-hover tx-white">
                            <thead class="thead-colored thead-primary">
                                <tr>
                                    <th>Reference</th>
                                    <th>Weight</th>
                                    <th>Created By</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="r in references">
                                    <td>{{r.reference}}</td>
                                    <td>{{r.weight}}g</td>
                                    <td>{{r.created_by}}</td>
                                    <td>
                                        <button v-if="$can('batch-delete')" class="btn btn-sm btn-danger" @click="removeFromBatch(r)">
                                            Remove from Batch
                                        </button>
                                    </td>
                                    <td>
                                        <button v-if="$can('batch-move')" class="btn btn-sm btn-primary" @click="openMoveToBatch(r)">
                                            Move to Batch
                                        </button>
                                    </td>
                                </tr>
                                <tr v-if="references.length < 1">
                                    <td colspan="3" class="text-center">No Enquiries</td>
                                </tr>
                            </tbody>
                        </table>


                    </div>
                </div>
            </div>
        </div>

        <!-- Move to Batch Modal -->
        <div class="modal fade" id="moveToBatch" tabindex="-1" role="dialog" aria-labelledby="moveToBatchLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content tx-14 bg-dark">
                    <div class="modal-header">
                        <h5 class="modal-title tx-white" id="moveToBatchLabel">Move to Batch</h5>
                        <button type="button" class="close tx-white" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div class="modal-body">
                            
                            <div v-if="remainingBatches.length > 0" class="form-group row">
                                <label class="col-md-3 col-form-label">Batch</label>
                                <div class="col-md-9">
                                    <select v-model="move.batch" class="form-control form-control-dark">
                                        <option v-for="batch in remainingBatches" :key="'batch-' + batch.id" :value="batch.id">{{batch.total_enquiries}} Claims - {{ batch.total_weight }}g - {{ batch.status}}</option>
                                    </select>
                                </div>
                            </div>
    
                            <div v-else class="alert alert-danger">
                                <strong>No Batches Available</strong>
                            </div>

                            <button v-if="move.batch" class="btn btn-primary pd-x-30 pd-y-15 tx-uppercase tx-bold tx-spacing-6 tx-12 wd-100p mt-2" @click="moveToBatch">Move</button>

                    </div>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
import {DateTime} from 'luxon';
import Pagination from "@/components/Pagination";
import Swal from 'sweetalert2';
import axios from 'axios';
import authHeader from '@/services/auth-header';

export default {
    name: "Checker",
    components: {
        Pagination
    },
    data(){
        return {
            references: [],
            current: "",
            credit: 0.00,
            cheque_number: "",
            posted: new Date().toISOString().slice(0, 10),
            signed_by: "",
            posting: false,
            rejected: [],
            previous: [],
            pagination: {
                currPage: 1,
                from: 1,
                lastPage: 1,
                perPage: 20,
                to: 1,
                totalResults: 0
            },
            editable: true,
            ref: null,
            filters: {
              date: "",
              reference: "",
              cheque_number: ""
            },
            pendingBatches: [],
            isPending: false,
            currentBatch: null,
            move: {
                batch: null,
                reference: null
            },
            generating: false
        }
    },
    mounted(){
      this.signed_by = this.$store.state.auth.user.email;
        this.loadPreviousBatches();
        this.loadPendingBatches();
    },
    methods: {
        loadPendingBatches(){
            axios.get(`https://api.varsanpr.com/api/claims/enquiry`, {
                headers: authHeader()
            })
            .then(response => {
                response.data.batches.forEach((batch) => {
                    batch.created_at = DateTime.fromSeconds(batch.created_at).toFormat('dd/MM/yyyy');
                });
                this.pendingBatches = response.data.batches;
            });
        },      
        addCurrent(){
            let claim = {
                uuid: Date.now(),
                reference: this.current.toUpperCase(),
                type: "",
                value: "",
                site: "",
                duplicates: false
            }
            this.references.push(claim);
            this.current = "";
            this.loadData(claim);
            this.checkDuplicates(claim);
        },
        remove(r){
            this.references = this.references.filter((claim) => {
                return claim.uuid !== r.uuid;
            });
            this.checkDuplicates(r);
        },
        loadData(claim){
            axios.get(`https://api.varsanpr.com/api/claims/find?reference=${claim.reference}`, {
                headers: authHeader()
            })
            .then(response => {
                if(response.data.claim){
                    switch(response.data.claim.type){
                        case 'driveoff':
                            claim.type = 'Drive Off';
                            break;
                        case 'escalated':
                            claim.type = 'Drive Off (No Means of Payment)';
                            break;
                        case 'nmop':
                            claim.type = 'No Means of Payment';
                            break;
                        case 'parking':
                            claim.type = 'Parking';
                            break;
                        case 'evparking':
                            claim.type = 'EV Parking';
                            break;
                        case 'cnaf':
                            claim.type = 'Customer Not at Fault';
                            break;
                        case 'cnafe':
                            claim.type = 'Drive Off (Customer Not at Fault)';
                            break;
                    }
                    if(['Cancelled', 'Paid', 'Paid on Site', 'Sent to Keeper', 'Letter Sent', 'Final Reminder'].includes(response.data.claim.status)){
                        Swal.fire({
                            title: 'Forbidden Status!',
                            text: 'This claim is currently set to ' + response.data.claim.status + ' and cannot be added to a batch.',
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        });
                        this.remove(claim);
                        document.getElementById('referenceInput').focus();
                    }else{
                        claim.site = response.data.claim.site.name;
                        claim.value = parseFloat(parseFloat(response.data.claim.value) + parseFloat(response.data.claim.value_second || 0)).toFixed(2);
                    }
                }
            })
            .catch(error => {
              this.$error("Claim could not be found", error);
            });
        },
        checkDuplicates(claim){
            let count = this.references.filter((c) => {
                return c.reference === claim.reference;
            });
            if(count.length > 1){
                count.forEach((c) => {
                    c.duplicates = true;
                });
            }else{
                count.forEach((c) => {
                    c.duplicates = false;
                });
            }
        },
        loadPreviousBatches(page = 1){
          let date = this.filters.date;
          if(date.length > 1){
            date = DateTime.fromJSDate(date);
            if(date.isValid){
              date = date.toFormat('yyyy-MM-dd');
            }else{
              date = "";
            }
          }
          let url = `https://api.varsanpr.com/api/claims/dvla?page=${page}`;
          if(date.length > 0){
            url += `&date=${date}`;
          }
          if(this.filters.reference.length > 0){
            url += `&reference=${this.filters.reference}`;
          }
          if(this.filters.cheque_number.length > 0){
            url += `&cheque_number=${this.filters.cheque_number}`;
          }
            axios.get(url, {
                headers: authHeader()
            })
            .then(response => {
                this.previous = response.data.data;
                this.previous.forEach((p) => {
                  p.created_at = DateTime.fromSeconds(p.created_at).toFormat('dd/MM/yyyy')
                })
                this.pagination.currPage = parseInt(response.data.pagination.page || 1);
                this.pagination.totalResults = response.data.pagination.total;
                this.pagination.from = 1 + (response.data.pagination.page -1) * response.data.pagination.showing;
                this.pagination.to = 1 + response.data.pagination.page * response.data.pagination.showing;
                if(this.pagination.to > this.pagination.totalResults){
                    this.pagination.to = this.pagination.totalResults;
                }
                this.pagination.perPage = response.data.pagination.showing;
                this.pagination.lastPage = response.data.pagination.lastPage;
            })
            .catch(error => {
                this.$error("Failed to load previous batches");
            })
        },
        selectBatch(batch){
            this.isPending = false;
          axios.get(`https://api.varsanpr.com/api/claims/dvla/${batch.id}`, {
            headers: authHeader()
          })
          .then(response => {
            if(response.data.success){
              this.references = [];
              this.editable = false;
              response.data.claims.forEach((c) => {
                this.credit = response.data.enquiry.credit;
                this.signed_by = response.data.enquiry.email;
                this.posted = DateTime.fromSeconds(response.data.enquiry.created_at).toFormat('yyyy-MM-dd');
                this.ref = batch.reference;
                this.cheque_number = batch.cheque_number;
                let claim = {
                    uuid: Date.now(),
                    reference: c.reference,
                    type: "",
                    value: "",
                    site: "",
                    duplicates: false
                }
                this.references.push(claim);
                this.loadData(claim);
                this.checkDuplicates(claim);
              })
            }
          })
        },
        selectPendingBatch(batch){
            this.isPending = true;
            axios.get(`https://api.varsanpr.com/api/claims/enquiry/${batch.id}`, {
                headers: authHeader()
            })
            .then(response => {
                if(response.data.success){
                    this.currentBatch = batch;
                    this.references = [];
                    this.credit = response.data.enquiry.credit;
                    this.cheque_number = response.data.enquiry.cheque_number;
                    this.signed_by = this.$store.state.auth.user.email;
                    response.data.claims.forEach((c) => {
                        let claim = {
                            uuid: Date.now(),
                            reference: c.reference,
                            weight: c.estimated_weight,
                            created_by: c.created_by,
                        }
                        this.references.push(claim);
                    });
                    this.editable = true;
                }
            })
        },
        newBatch(){
          this.references = [];
          this.credit = 0.00;
          this.signed_by = "";
          this.cheque_number = "";
          this.posted = new Date().toISOString().slice(0, 10);
          this.ref = null;
          this.editable = true;
        },
        postBatch(){

          if(!this.editable) return;

          if(this.isPending){
            this.postPendingBatch();
            return;
          }

          this.posting = true;
          this.rejected = [];
          axios.post(`https://api.varsanpr.com/api/claims/dvla`, {
              credit: this.credit,
              cheque: this.value,
              claims: this.references,
              cheque_number: this.cheque_number,
              reference: this.batchref
          }, {
            headers: authHeader()
          })
          .then(response => {

              this.posting = false;
              this.references = [];
              this.credit = 0.00;
              this.loadPreviousBatches();
              this.$success("Batch Posted");

              if(response.data.rejected.length > 0){
                this.rejected = response.data.rejected;
                this.$warning("Some claims were rejected");
              }
            
          })
          .catch(error => {
            this.$error("Failed to post batch", error);
          })

        },
        postPendingBatch(){

        },
        generateBatch(){
            
        },  
        removeFromBatch(reference){
            if(!this.$can('batch-delete')) return;
            if(reference.removing) return;
            reference.removing = true;
            axios.delete(`https://api.varsanpr.com/api/claims/enquiry/${this.currentBatch.id}`, {
                headers: authHeader(),
                data: {
                    reference: reference.reference
                }
            })
            .then(response => {
                if(response.data.success){
                    this.references = this.references.filter((r) => {
                        return r.reference !== reference.reference;
                    });
                    this.currentBatch.total_enquiries--;
                    this.currentBatch.total_weight -= reference.weight;
                    this.$success("Claim removed from batch");
                }
            })
            .catch(error => {
                this.$error("Failed to remove claim from batch", error);
            })
            .finally(() => {
                reference.removing = false;
            })
        },
        openMoveToBatch(reference){
            if(!this.$can('batch-move')) return;
            this.move.reference = reference;
            this.move.batch = null;
            $('#moveToBatch').modal('show');
        },
        moveToBatch(){
            if(!this.$can('batch-move')) return;
            if(!this.move.batch) return;
            axios.post(`https://api.varsanpr.com/api/claims/enquiry/${this.currentBatch.id}`, {
                reference: this.move.reference.reference,
                batch_id: this.move.batch
            }, {
                headers: authHeader()
            })
            .then(response => {
                if(response.data.success){
                    this.references = this.references.filter((r) => {
                        return r.reference !== this.move.reference.reference;
                    });
                    this.currentBatch.total_enquiries--;
                    this.currentBatch.total_weight -= this.move.reference.weight;
                    let batch = this.pendingBatches.find((b) => {
                        return b.id === this.move.batch;
                    });
                    batch.total_enquiries++;
                    batch.total_weight += this.move.reference.weight;

                    this.move.batch = null;
                    this.move.reference = null;

                    this.$success("Claim moved to batch");
                    $('#moveToBatch').modal('hide');
                }
            })
            .catch(error => {
                this.$error("Failed to move claim to batch", error);
            })
        }
    },
    computed: {
        value: function(){
            return parseFloat((this.references.length * 2.50) - this.credit).toFixed(2);
        },
        duplicates: function(){
            let duplicates = this.references.filter((c) => {
                return c.duplicates === true;
            });
            return duplicates.length;
        },
        batchref: function(){

          return this.ref || "DVLA" + this.posted.split('-').reverse().join('') + '/' + Buffer.from(new Date().toISOString().split('.')[1].replace('Z', '')).toString('base64').substr(0, 4);

        },
        remainingBatches: function(){
            return this.pendingBatches.filter((b) => {
                return b.id !== this.currentBatch?.id
            });
        }
    }
}
</script>

<style scoped>
    .duplicates > td {
        color: orange;
    }
</style>
